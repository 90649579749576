export const MenuItems = [
  {
    title: "Hakkımızda",
    path: "/hakkimizda",
    cName: "dropdown-link",
  },
  {
    title: "Misyonumuz",
    path: "/misyon",
    cName: "dropdown-link",
  },
  {
    title: "Vizyonumuz",
    path: "/vizyon",
    cName: "dropdown-link",
  },
  {
    title: "Kalite Politikamız",
    path: "/kalite",
    cName: "dropdown-link",
  },
];
